export const API_URL = 'https://api.pomarancze.com/';
export const apaczka_app_id = '2127973_3qy4jiUXkWfRuWJmv7BuxWHe';

export const modalIds = {
  basic: 'basic-modal',
  checkout: 'checkout',
  newsletter: 'newsletter',
};

export const QUERY_ID = {
  GET_CART: 'GET_CART',
  GET_ORDERS: 'GET_ORDERS',
  GET_PROFILE: 'PROFILE',
  GET_LINK: 'GET_LINK',
  GET_POSTS: 'GET_POSTS',
  GET_POST: 'GET_POST',
};

export const products = [
  {
    id: 1,
    title: 'Soczyste pomarańcze',
    name: 'Pomarańcze',
    desc: 'Świeże, pełne smaku pomarańcze, które zachwycą każdego.',
    country: 'Hiszpania',
    price: 120,
    weight: 10,
    unit: 'kg',
    shopPageImg: '/oranges.png',
    image: '/our-product-bg-1.png',
    route: '/shop/1',
  },
  {
    id: 2,
    title: 'Soczyste cytryny',
    name: 'Cytryny',
    desc: 'Idealne do soków, potraw i jako zdrowa przekąska.',
    country: 'Hiszpania',
    price: 120,
    weight: 10,
    unit: 'kg',
    shopPageImg: '/lemons.png',
    image: '/our-product-bg-2.png',
    route: '/shop/2',
  },
  {
    id: 3,
    title: 'Oliwa z oliwek',
    name: 'Oliwa z oliwek',
    desc: 'Wysokiej jakości oliwa, która doda wyjątkowego smaku każdemu daniu.',
    country: 'Hiszpania',
    price: 70,
    weight: 1,
    unit: 'L',
    shopPageImg: '/oil.png',
    image: '/our-product-bg-3.png',
    route: '/shop/3',
  },
];

export const freeProduct = {
  id: 4,
  title: 'Soczyste pomarańcze',
  name: 'Pomarańcze',
  desc: 'Świeże, pełne smaku pomarańcze, które zachwycą każdego.',
  country: 'Hiszpania',
  price: 0,
  weight: 10,
  unit: 'kg',
  shopPageImg: '/oranges.png',
  image: '/our-product-bg-1.png',
  route: '/shop/1',
};

export const courierOptions = [
  {
    name: 'courier',
    value: '42',
    title: 'Kurier InPost',
    price: 0,
    image: '/inpost.png',
  },
  {
    name: 'courier',
    value: 'package',
    title: 'InPost Paczkomaty',
    price: 0,
    image: '/inpost.png',
  },
];
