import { modalIds, QUERY_ID } from '@/constants';
import { FormikFormField } from './FormikFormField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import API from '@/api';
import { useQuery } from '@tanstack/react-query';
import { ProfileResponse } from '@/utils/types';
import { useEffect, useState } from 'react';

function Line(props: { title: string; order: number }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="w-[41px] h-[41px] flex flex-col justify-center items-center rounded-full bg-[#9BC918]">
        <div className="text-[20px] text-white font-extrabold">
          {props.order}
        </div>
      </div>
      <div className="font-bold text-[20px] text-[#9BC918]">{props.title}</div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Imię jest wymagane'),
  lastname: Yup.string().required('Nazwisko jest wymagane'),
  phone: Yup.string().required('Numer telefonu jest wymagany'),
  region: Yup.string().required('Województwo jest wymagane'),
  city: Yup.string().required('Miejscowość jest wymagana'),
  address: Yup.string().required('Adres jest wymagany'),
  postal: Yup.string().required('Kod pocztowy jest wymagany'),
});

export function BasicModal() {
  const [profileData, setProfileData] = useState<ProfileResponse | undefined>();
  const { data: profile, refetch } = useQuery({
    queryKey: [QUERY_ID.GET_PROFILE],
    queryFn: () =>
      API.get('profile/').then((res) => res.data as ProfileResponse),
  });

  useEffect(() => {
    if (profile) {
      setProfileData(profile);
    }
  }, [profile]);

  return (
    <dialog id={modalIds.basic} className="modal !border-0">
      <div className="modal-box !w-[80%] lg:!w-[91%]">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        {profileData && (
          <Formik
            initialValues={{
              name: profileData.first_name ?? '',
              lastname: profileData.last_name ?? '',
              phone: profileData.phone_number ?? '',
              region: profileData.province ?? '',
              city: profileData.city ?? '',
              address: profileData.street ?? '',
              postal: profileData.postal_code ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              await toast.promise(
                API.put('profile/', {
                  phone_number: values.phone,
                  province: values.region,
                  postal_code: values.postal,
                  first_name: values.name,
                  last_name: values.lastname,
                  city: values.city,
                  street: values.address,
                }),
                {
                  pending: 'Zapisywanie zmian...',
                  success: 'Zapisano zmiany',
                  error: 'Wystąpił błąd przy zapisywaniu',
                },
              );
              refetch();
              (
                document.getElementById(
                  modalIds.basic,
                ) as HTMLDialogElement | null
              )?.close();
            }}
          >
            {({ values, errors, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className={`flex flex-col gap-4`}>
                <div className={`flex flex-col gap-4`}>
                  <Line title="Dane personalne" order={1} />
                  <div className="grid grid-cols-2 gap-4">
                    <FormikFormField
                      placeholder="Imię"
                      name="name"
                      value={values.name}
                    />
                    <FormikFormField
                      placeholder="Nazwisko"
                      name="lastname"
                      value={values.lastname}
                    />
                    <FormikFormField
                      placeholder="Numer telefonu"
                      name="phone"
                      value={values.phone}
                    />
                  </div>
                  <Line title="Dane do dostawy" order={2} />
                  <div className="grid grid-cols-2 gap-4 pb-8">
                    <FormikFormField
                      placeholder="Województwo"
                      name="region"
                      value={values.region}
                    />
                    <FormikFormField
                      placeholder="Ulica i numer domu"
                      name="address"
                      value={values.address}
                    />
                    <FormikFormField
                      placeholder="Miejscowość"
                      name="city"
                      value={values.city}
                    />
                    <FormikFormField
                      placeholder="Kod pocztowy"
                      name="postal"
                      value={values.postal}
                    />
                  </div>
                  <Line title="Bezpieczeństwo" order={3} />
                  <div className="flex flex-row gap-4">
                    <FormikFormField
                      placeholder="Hasło"
                      name="password"
                      class="w-full"
                    />
                    <button className="bg-transparent border border-solid border-black text-black font-bold rounded-[25px] h-[49px] px-4 min-w-[140px] cursor-pointer">
                      Zmień hasło
                    </button>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.9585 4.4526C4.71267 4.91844 3.69434 6.39177 3.69434 7.71344V15.7626C3.69434 17.0409 4.53934 18.7201 5.5685 19.4893L10.2268 22.9668C11.7543 24.1151 14.2677 24.1151 15.7952 22.9668L20.4535 19.4893C21.4827 18.7201 22.3277 17.0409 22.3277 15.7626V7.71344C22.3277 6.38094 21.3093 4.9076 20.0635 4.44177L14.6577 2.41594C13.7368 2.0801 12.2635 2.0801 11.3643 2.41594L5.9585 4.4526Z"
                        stroke="#8EB520"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.8042 12.8595L11.5484 14.6036L16.2067 9.94531"
                        stroke="#8EB520"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="text-[#9BC918] font-bold text-[14px]">
                      Dbamy o twoje bezpieczeństwo
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:items-center gap-4 justify-between">
                    <div className="text-[#BDBDBD] text-[14px]">
                      Utrzymujemy standardowe w branży środki fizyczne,
                      techniczne i administracyjne w celu ochrony danych
                      osobowych.
                    </div>
                    <div>
                      <a
                        className="text-black font-bold text-[14px]"
                        href="/privacy"
                        target="_blank"
                      >
                        Polityka prywatności i plików cookie
                      </a>
                    </div>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="text-red-500 text-xs">
                      <ul>
                        {Object.values(errors).map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <button
                    type="submit"
                    className="rounded-[86px] font-bold text-white bg-[#F47E01] border-none outline-none h-[42px] max-w-[200px] cursor-pointer"
                  >
                    Zapisz zmiany
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </dialog>
  );
}
