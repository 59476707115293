import { ReactNode, useEffect } from 'react';

import API from '@/api';
import { QUERY_ID } from '@/constants';
import { CartResponse } from '@/utils/types';
import { useQuery } from '@tanstack/react-query';
import { BasicModal } from './BasicModal';
import { CheckoutModal } from './CheckoutModal';
import Footer from './Footer';
import { useSearchParams } from 'next/navigation';
import { toast } from 'react-toastify';
import { NewsletterModal } from './NewsletterModal';

type AppWrapperType = {
  children: ReactNode;
};

const AppWrapper = ({ children }: AppWrapperType) => {
  const params = useSearchParams();
  const refferalCode = params.get('ref');

  useEffect(() => {
    if (refferalCode) {
      toast.promise(
        API.post('affiliate/check-code/', { referral_code: refferalCode }),
        {
          pending: 'Ustawianie kodu...',
          success: 'Ustawiono kod pomyślnie!',
          error: 'Wystąpił błąd podczas ustawiania kodu.',
        },
      );
    }
  }, [refferalCode]);

  const { data } = useQuery({
    queryKey: [QUERY_ID.GET_CART],
    queryFn: () => API.get('cart/').then((res) => res.data as CartResponse),
  });

  return (
    <div>
      <main>{children}</main>
      <CheckoutModal />
      <BasicModal />
      <NewsletterModal />
      <button
        onClick={() =>
          (
            document.getElementById('checkout-modal') as HTMLDivElement | null
          )?.classList.remove('!hidden')
        }
        className="fixed bottom-0 w-[165px] bg-[#F47E01] rounded-b-[61px] btn btn-sm btn-circle btn-ghost text-white ml-4"
      >
        <div>Pokaż koszyk</div>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 10L8 6L4 10"
            stroke="#ffffff"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Footer />
    </div>
  );
};

export default AppWrapper;
