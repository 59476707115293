import api from '@/api';
import { modalIds } from '@/constants';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  mail: Yup.string()
    .email('Nieprawidłowy adres e-mail')
    .required('E-mail jest wymagany'),
});

export function NewsletterModal() {
  return (
    <dialog id={modalIds.newsletter} className="modal !border-0">
      <div className="modal-box flex flex-col gap-8 pb-12 !w-[80%] lg:!w-[90%]">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <div className="text-[35px] font-extrabold">
          Nie przegap powiadomienia{' '}
          <span className="text-[#F47E01]">
            o starcie sprzedaży świeżych produktów!
          </span>
        </div>
        <div className="text-[16px] text-black">
          Zapisz się do newslettera i otrzymaj powiadomienie
        </div>
        <Formik
          initialValues={{
            mail: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await toast.promise(
              api.post('newsletter/subscribe/', { email: values.mail }),
              {
                pending: 'Zapisywanie do newslettera...',
                success: 'Zapisano do newslettera',
                error: 'Wystąpił błąd przy zapisywaniu',
              },
            );
          }}
        >
          {({ values, errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row gap-8 h-[65px] rounded-[86px]">
                <Field
                  placeholder="Wpisz swój adres e-mail"
                  className="rounded-[86px] px-6"
                  name="mail"
                  value={values.mail}
                />
                <button
                  type="submit"
                  className="cursor-pointer uppercase text-[13px] md:text-[16px] text-white font-semibold flex flex-row justify-center items-center bg-[#F47E01] rounded-[86px] outline-none border-none min-w-[100px] lg:min-w-[150px] h-[65px]"
                >
                  <div>Zapisz się</div>
                </button>
              </div>
              {Object.keys(errors).length > 0 && (
                <div className="text-red-500 text-xs">
                  <ul>
                    {Object.values(errors).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </dialog>
  );
}
